angular.module('fingerink')
    .controller('userNavabarCtrl', function ($scope, $rootScope, userService, loginService, session,  changeSignatureModal, signatureService) {

        $scope.controller = this;
        var that = this;

       
        that.plan = session.get().plan;
        that.user = session.get().user;
        that.credits = session.getRemainingCredits();
    
        that.logout = loginService.logout;

        
        $scope.$on("sessionChanged", function () {
            that.plan = session.get().plan;        
            that.user = session.get().user;
            that.credits = session.getRemainingCredits();
        });
    
        that.session = session.get();
        if (that.session.plan && that.session.plan.orderPeriods && that.session.plan.orderPeriods[0] && that.session.plan.orderPeriods[0].orderPeriodStates && that.session.plan.orderPeriods[0].orderPeriodStates[0] ) {
            that.product = that.session.plan.orderProduct;
            //that.plan = that.session.plan;
            that.serviceStates = that.session.plan.orderPeriods[0].orderPeriodStates[0];
        }


        ///////////////////////////////
        // Firma                    //
        //////////////////////////////
        userService.singImageURL().then(function (response) {
            that.userFirmaImage = response.data;
        });

        that.verFirma = function () {
            changeSignatureModal.openModal().result.then(function (data) {
                if (data) {
                    that.userFirmaImage = data;
                    $scope.$broadcast("signatureChanged", data);
                }

            });
        };
    
        $scope.$on('signatureChanged', function(data, signature){
            that.userFirmaImage = signature;
        });

        //////////////////////////////////////
    
        
        
    
    });
